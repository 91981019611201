import React from 'react';

function getMonthName(month, language) {
  let monthName = '';

  if (language === 'ca') {
    const catalanMonths = [
      'gener', 'febrer', 'març', 'abril', 'maig', 'juny',
      'juliol', 'agost', 'setembre', 'octubre', 'novembre', 'desembre'
    ];
    monthName = catalanMonths[month - 1];
  } else if (language === 'es') {
    const spanishMonths = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
    monthName = spanishMonths[month - 1];
  } else if (language === 'en') {
    const englishMonths = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    monthName = englishMonths[month - 1];
  } else {
    monthName = 'Invalid language';
  }

  return (
    monthName
  );
}

export default getMonthName;
